import onPageReady from './utils/onPageReady';
import polyfill from './polyfills/polyfill-loader';

import '@splidejs/splide/dist/js/splide.min.js';

const sentryDsn = 'https://815212f18a7b4352b607332ceeb75d3e@o37692.ingest.sentry.io/5555589';

function initialise() {
    /*  Mobile Menu  ------------------------ */
    const mobileNavEl = document.querySelector('.mobile-nav');
    if (mobileNavEl) {
        import(/* webpackChunkName: "mobile-nav" */ './modules/mobile.nav.js')
            .then(({ default: MobileNav }) => {
                new MobileNav(mobileNavEl);
            });
    }

    if (sentryDsn) {
        import(/* webpackChunkName: "sentry" */ './modules/sentry.js')
            .then(({ default: sentry }) => {
                sentry.init(sentryDsn);
            });
    }

    /*  Tables  ------------------------ */
    const tables = document.querySelectorAll('table');
    if (tables.length > 0) {
        import(/* webpackChunkName: "tables" */ './modules/responsive-tables.js')
            .then(({ default: tablesInit }) => {
                tablesInit([...tables]);
            });
    }

    /*  Results Pagination ---------------- */
    const paginationButton = document.querySelector('.table--pagination-btn');
    if (paginationButton) {
        import(/* webpackChunkName: "tables" */ './modules/results-pagination.js')
            .then(({ default: pagintationInit }) => {
                pagintationInit(paginationButton);
            });
    }

    /*  Collapsibles  ------------------------ */
    const collapsibleEls = document.querySelectorAll('[data-collapse-control], .collapse-control');
    if (collapsibleEls.length > 0) {
        import(/* webpackChunkName: "collapsible" */ './modules/collapsible.js')
            .then(({ default: collapsibleInit }) => {
                collapsibleInit([...collapsibleEls]);
            });
    }

    /*  Accordion  ------------------------ */
    const accordionHeader = document.querySelectorAll('.accordion-header');
    if (accordionHeader.length > 0) {
        import(/* webpackChunkName: "accordion" */'./modules/accordion.js')
            .then(({ default: initAccordion }) => {
                initAccordion();
            });
    }

    const people = document.querySelectorAll('.card--staff');
    if (people.length > 0) {
        import(/* webpackChunkName: "people-page" */ './modules/people-page.js')
            .then(({ default: peoplePageInit }) => {
                peoplePageInit([...people]);
            });
    }

    const userPrefersReducedMotion = window.matchMedia('(prefers-reduced-motion: reduce)').matches;
    if (userPrefersReducedMotion) {
        import(/* webpackChunkName: "accessibility" */ './modules/accessibility.js')
            .then(({ default: accessibilityInit }) => {
                accessibilityInit({ userPrefersReducedMotion });
            });
    }

    const splideContainer = document.getElementById('splide');
    if (splideContainer) {
        import(/* webpackChunkName: "splide" */ './modules/splide.js')
            .then(({ default: initSplide }) => {
                initSplide();
            });
    }

    if (document.querySelectorAll('[data-reoako-id]')) {
        import('./modules/reoako.js')
            .then(({ default: reoakoInit }) => {
                reoakoInit();
            });
    }

    /*  Subscription CTA  ------------- */
    const subscriptionForms = document.querySelectorAll("[id^='subscription-form']");
    if (subscriptionForms.length > 0) {
        import(/* webpackChunkName: "subscription-form" */ './modules/subscription-cta.js')
            .then(({ default: subscriptionInit }) => {
                for (const form in subscriptionForms) {
                    if (subscriptionForms.hasOwnProperty(form)) {
                        subscriptionInit(subscriptionForms[form]);
                    }
                }
            });
    }
}

polyfill().then(() => onPageReady(initialise));
